/* You can add global styles to this file, and also import other style files */
// show for screen readers only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.cursor-pointer {
  cursor: pointer;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

#tooltip {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 4px;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: none; /* Ensure tooltip doesn't interfere with mouse events */
}

.lds-dual-ring {
  /* change color here */
  color: #a2a6a7
}
.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-user {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

.for-print {
  display: none;
}

.dropdown-menu-arrow {
  &:before {
    background: #ffffff;
    box-shadow: none;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(12px);
    z-index: -5;
    border-radius: 2px;
  }
}

.dropdown-menu-right {
  &:before {
    right: 20px;
    left: auto;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: transparent !important;
  }
  @page {
    size: A4 landscape !important;
  }
  .table-user {
    max-width: auto !important;
    overflow: auto !important;
    text-overflow: unset !important;
  }
}

/* Custom */

.bg-primary {
  background-color: var(--primary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bs-datepicker-predefined-btns button:hover {
  background-color: var(--blue-50) !important;
}

.bs-datepicker-predefined-btns button {
  background-color: var(--primary-500) !important;
}

.p-tree {
  max-height: 50vh;
  overflow: auto;
}

.dropdown-menu.show {
  max-height: 290px !important;
  overflow: auto !important;
}

.p-dropdown {
  width: 100% !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.25rem 0.55rem !important;
  font-size: 0.875rem !important;
}

.hide-toggle > .dropdown-toggle::after {
  display: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}
